
@import 'mixin';
@import 'font-awesome.min.css';
/* Fonts */
@include font-face('HelveticaNeueLTStdHv', 'HelveticaNeueLTStdHv', '', 'sans-serif');
@include font-face('HelveticaNeueLTStdBlk', 'HelveticaNeueLTStdBlk', '', 'sans-serif');
@include font-face('HelveticaNeueLTStdBd', 'HelveticaNeueLTStdBd', '', 'sans-serif');
@include font-face('HelveticaNeueLTStdBdIt', 'HelveticaNeueLTStdBdIt', '', 'sans-serif');
@include font-face('HelveticaNeueLTStdLt', 'HelveticaNeueLTStdLt', '', 'sans-serif');
@include font-face('HelveticaNeueLTStdLtIt', 'HelveticaNeueLTStdLtIt', '', 'sans-serif');
@include font-face('HelveticaNeueLTStdMd', 'HelveticaNeueLTStdMd', '', 'sans-serif');
@include font-face('DDINCondensedBold', 'DDINCondensedBold', '', 'sans-serif');


/* Common */
$fstack_helveticahv: 'HelveticaNeueLTStdHv', sans-serif;
$fstack_helveticabd: 'HelveticaNeueLTStdBd', sans-serif;
$fstack_helveticabdit: 'HelveticaNeueLTStdBdIt', sans-serif;
$fstack_helveticamd: 'HelveticaNeueLTStdMd', sans-serif;  
$fstack_helveticalt: 'HelveticaNeueLTStdLt', sans-serif;
$fstack_helveticaltit: 'HelveticaNeueLTStdLtIt', sans-serif;
$fstack_dincb: 'DDINCondensedBold', sans-serif;
$fstack_helveticablk: 'HelveticaNeueLTStdBlk', sans-serif;

body{ background: #000; font-size: 21px; font-family: $fstack_helveticamd; 
   color: #424243; line-height: normal; }
.clear{ clear:  both;}
.clearfix:after{content: ""; display: block; clear: both;}
a{ color: inherit; transition: all 400ms; -webkit-transition: all 400ms ease; cursor: pointer; outline: none; text-decoration: none;}
a:hover, a:focus{ color: #d6b861; outline: none; text-decoration: none;}
strong, b{ font-weight: bold; }
img{ max-width: 100%; vertical-align: top;}
.btn{ cursor: pointer;}
textarea{ resize: none;}
.required_star{ color: #f00;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{ margin: 0 0 20px; font-weight: normal;}
p{ margin: 0 0 20px;}
ul{margin: 0 0 20px;}
li{ margin-bottom: 6px;}

/* Button */
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus{outline: none; box-shadow: none;}
.btn{ padding: 10px 40px; height: 67px; font-size: 39px; font-family: $fstack_helveticahv; color: #fff; display: inline-block;  border: 1px solid transparent; border-radius: 40px; position: relative; letter-spacing: 4px; line-height: normal; transition: all .6s ease;
    
    /*&:before{ content: ""; background: url(../images/particales-black.jpg) repeat 0 0; background-size: 140px; position: absolute; left: -1px; bottom: -1px; width: calc(100% + 2px); height: calc(100% + 2px); opacity: 0; }
    &:hover:before{ opacity: 1; animation: bg-scrolling-banner 25s infinite;  animation-timing-function: linear;}*/
}
.btn-sm{ font-size: 24px; padding: 10px 40px; height: 50px; letter-spacing: 0; }
.btn-lg{ font-size: 35px; border-radius: 0; padding: 28px 40px; height: 100px; letter-spacing: 0; }
.btn_sec{
  .btn{ margin-left: 10px; }
}
.btn_minwid{ min-width: 230px;}
.btn:first-child{ margin-left: 0;}
.btn_yellow{ background-color: #d6b860; color: #424243; }
.btn_yellow:hover, .btn_yellow:focus{background: #000; color: #fff;}
.btn_yellow:before{ border-radius: 40px;}


   /* table */
table{ width: 100%; max-width: 100%; margin-bottom: 20px; }
.table_overflow { overflow-x:auto; overflow-y:hidden; margin-bottom: 20px;}
.table_overflow {
  .table{ margin-bottom: 0; }
}
/* Form */
.form-group{ margin-bottom: 15px;}
.form_sec {
  .row{ margin-left: -7.5px; margin-right: -7.5px;}
  [class*="col-"]{ padding: 0 7.5px;}
}
label{ font-weight: 600; }
.input_field{ position: relative;}
.form-group label{ display: block; margin: 0 0 3px;}
.form-control{ background: #ffffff; height: 55px; font-size: 21px; border: 2px solid #f1f2f2; box-shadow: none; -webkit-box-shadow: none; padding: 6px 20px; background: #ffffff; border-radius: 50px; color: #505053; }
textarea.form-control { height: 90px; border-radius: 18px;}
.form-control.required{ background-image: url(../images/required-star.png); background-repeat: no-repeat; background-position: right 20px center; background-size: 8px auto; -webkit-background-size: 8px auto; padding-right: 30px;}
.form-control.error{ background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"); background-size: calc(2.25rem / 2) calc(2.25rem / 2); background-repeat: no-repeat; background-position: right 15px center; padding-right: 40px; border-color: #dc3545;}
.form-control:focus{ box-shadow: none; -webkit-box-shadow: none; border-color: #d6b860; }
label.error{ background: #FFBABA; color: #D8000C !important; padding: 5px 15px; font-size: 16px !important; position: relative; text-transform: inherit !important; display: inline-block; font-weight: normal; margin: 0 !important; border-radius: 30px;}
.error_common { padding: 5px 8px; border-radius: 3px; background: #FFBABA; color: #D8000C !important; text-align: center; margin: 0 0 15px; }
label.error:before{   border-bottom: 5px solid #ffbaba;  border-left: 5px solid transparent;
    border-right: 5px solid transparent;  content: "";   left: 0;   margin: auto;  position: absolute;  right: 0;  top: -5px;  width: 0;}
.center_formsec label.error{ text-align: center; display: block; margin: 0 auto;}
.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #a8aaad; font-family: $fstack_helveticaltit;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #a8aaad; font-family: $fstack_helveticaltit;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #a8aaad; font-family: $fstack_helveticaltit;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  color: #a8aaad; font-family: $fstack_helveticaltit;
}
.form-control:focus + .input_glowanimation{
    animation: anim-shadow 0.3s forwards;
}
.input_glowanimation {
    content: ''; position: absolute; top: 0; z-index: -1; pointer-events: none; width: 100%; height: 100%; box-shadow: 0px 0px 0px 0px ;  border-radius: 40px;  
}


.invalid-feedback{ padding-left: 33px;}
.form-text{ font-size: 85%;
  i{margin: 8px 10px 0 0; line-height: 1; display: inline-block;vertical-align: top;}
}
.form-control.error{ background-image: none;}

/* Select */
.custom_select {
    .css-yk16xz-control { background: #f1f2f2; border-color: #f1f2f2; border-radius: 50px;}
    .css-1hwfws3 { padding: 10.5px 20px; }
    .css-1okebmr-indicatorSeparator { background: none; }     
    .css-tlfecz-indicatorContainer+.css-1okebmr-indicatorSeparator { background: #f1f2f2;}
    .css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer { padding: 11px 15px; color: #000; cursor: pointer}
    .css-yk16xz-control:hover { border-color: #f1f2f2}
    .css-1pahdxg-control { border-radius: 50px; box-shadow: none}
    .css-1pahdxg-control:hover { border-color: #d6b860}
    .css-1uccc91-singleValue{ color: #424243;}
    .css-1rhbuit-multiValue { background-color: #f1e8cc}
    .css-12jo7m5 { color: #d6b861}
    .css-1alnv5e { color: #d6b861; cursor: pointer; padding-left: 2px; padding-right: 2px}
    .css-1alnv5e:hover { background-color: #eed37f; color: #d6b861}
    .css-1alnv5e svg { width: 11px; height: 11px}
    .css-tj5bde-Svg { width: 14px; height: 14px}
    .css-26l3qy-menu{ z-index: 3;}
    .css-1n7v3ny-option{ background-color: rgba(214, 184, 96, 0.68);
        &:active{ background-color: rgba(214, 184, 96, 1);} 
        &:active:foucs{ background-color: rgba(214, 184, 96, 1);}       
    }
    .css-9gakcf-option{ background-color: rgba(214, 184, 96, 1); color: #424243;}    
}

    /* Check box */
.custom_checkbox{ position: relative; display: inline-block;
   input{ opacity: 0; filter: alpha(opacity=0); height: 100%; left:0; top: -1px; margin: 0; width: 100%; position: absolute; cursor: pointer;}
   input[type=checkbox]:checked + span:after{  width: 32px;}
   span{ position: relative; padding: 6px 0 6px 46px; pointer-events: none; display: block;
        &:before{ content: ""; background: #e6e7e7; position: absolute; width: 35px; height: 35px; border: 1px solid #e6e7e7; left: 0; top: 0; pointer-events: none; line-height: 33px;  font-size: 24px;  }
        &:after{ content: ""; background: url(../images/tick.png) no-repeat; width: 0; height: 25px; position: absolute; left: 9px; top: -2px; transition: all 400ms;}
   }   
  }
    /* Radio */  
.custom_radio{ position: relative; display: inline-block;
   input{ opacity: 0; filter: alpha(opacity=0); height: 100%; left:0; top: -1px; margin: 0; width: 100%; position: absolute; cursor: pointer;}
   input[type=radio]:checked + span:before{ content: "\f111"; font-family: FontAwesome; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color: #00b01d; text-align: center; }
   span:before{ content: ""; background: #e6e7e7; position: absolute; width: 35px; height: 35px; border: 1px solid #e6e7e7; left: 0; top: 0; line-height: 33px; font-size: 24px; text-indent: 1px; pointer-events: none; border-radius: 50%; }
   span{ position: relative; padding: 2px 0 2px 46px; pointer-events: none; display: block;}
  }

    /* Loading */
.load_btn{ position: relative; }
.load_btn:after {
  content: " ";
    display: block;
    background: 0;
    animation: ldld-default .5s ease-in-out infinite;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute; right: 15px; top: 0; bottom: 0;
    margin: auto 0;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-color: currentColor transparent currentColor transparent;
}
@-webkit-keyframes ldld-default{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
@keyframes ldld-default{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}

.load-wrapper, .load_wrapper{
    position:relative;       
}
.load-wrapper-mheight{min-height: 350px;}
.load-wrapper:after, .load_wrapper:after{ content:""; position: absolute; left: 0; top: 0; bottom: 0; right: 0; background:rgba(255,255,255,0.8);}
.load-msg {
    position: absolute;    
    z-index: 2;
    opacity: 1;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
}
.load-msg .in {
    text-align: center
}
.load-msg .in.b span {
    color: #070707
}
.load-msg span {
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: white;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
}
.load-msg span em {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    display: block;
    margin: 3px 0px 0px 0px;
    transform: translateY(30px);
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
}
.load-msg .load-anim {
    display: block;
    width: 55px;
    height: 55px;
    margin: 0 auto;
}
.load-msg .load-anim .sk-cube {
    width: 27%;
    height: 27%;
    background-color: #d6b860;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out
}
.load-msg .load-anim .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s
}
.load-msg .load-anim .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s
}
.load-msg .load-anim .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s
}
.load-msg .load-anim .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s
}
.load-msg .load-anim .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s
}
.load-msg .load-anim .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s
}
.load-msg .load-anim .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}
.load-msg .load-anim .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s
}
.load-msg .load-anim .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s
}
.load-msg .load-anim.b .sk-cube {
    background-color: #d6b860; margin: 0.5px;
}
.load-msg .load-anim.b div:nth-child(2n+1) {
    background: #d6b860;
}
.load-msg.show {
    opacity: 1
}
.load-msg.show span {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px)
}
.load-msg.show span em {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px)
}
.load-msg.right {
    left: 75%
}
@media (max-width: 767px) {
    .load-msg.right {
        left: 50%
    }
}
@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    background: #d6b860;
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    background: #d6b860;
    }
}
@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    background: #d6b860;
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    background: #d6b860;
    }
}
  /* Loading initial */
.loading_initial{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; margin: auto;}
.loading_initial span{ width: 247px; height: 205px; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;}
/*.loading_initial span:after{ content: ""; background: url(../images/ichapter-logo1.png) no-repeat bottom center; position: absolute; left: 0; right: 0; bottom: 0; animation: fillpath 3s infinite alternate; animation-timing-function: ease in out;}*/
  
/* inital gif loading */
@keyframes fillpath{
   0% {height: 0;}   
   100% {height: 100%;}
}
/* Loading end */


  /* Alert */
  .alert{ padding: 12px 25px; font-size: 18px; font-family: $fstack_helveticalt;}
      
    /* Helper class */
.d_flex{ display: flex;}
.d_inblock{ display: inline-block;}/* Display inline-block; */
.d_block{ display: block;}/* Display block; */
.relative{ position: relative;}
.vtop{ vertical-align: top;}
.vmiddle{ vertical-align: middle;}
.vbottom{ vertical-align: bottom;}
.text_transformnn{ text-transform: none;}
.align-right{ float: right; margin-left: 40px;}
.align-left{ float: left; margin-right: 40px;}
.align-center{ margin: 0 auto 20px; display: block;}

.container{ @include spacearround(50px); max-width: 100%;}
.title1{ font-family: $fstack_dincb; font-size: 49px; color: #fff; margin: 0 0 20px;}
.title2{ font-family: $fstack_helveticabd; font-size: 39px; color: #fff; margin: 0 0 20px;}
/* Common end */


/* Landing page */
.center{margin: 0 auto; max-width: 768px; background: #fff; position: relative; min-height: 100vh; overflow-x: hidden;}
.land_wrapper{ background: url(../images/landing_bg.jpg) no-repeat center; background-size: cover; -webkit-background-size: cover; min-height: 100vh;}
.land_content{ padding: 120px 0 12px; min-height: calc(100vh - 88px); min-height: -webkit-calc(100vh - 88px);
    .container{@include spacearround(100px);}
}
.lading_logo{ text-align: center; margin: 0 0 60px;}
.form_sec{ position: relative; z-index: 1;}
.landing_form{ margin: 0 0 35px;
     .title1{ letter-spacing: 16px; text-align: center; text-shadow: 0px 6px 3px rgba(0,0,0,0.9);}
    .form-control{ text-align: center; height: 66px; font-size: 31px;}
}
.lading_remember{ margin-top: 23px;
    .custom_checkbox span{ color: #e6e7e7; font-family: $fstack_helveticalt;}
    .custom_checkbox span:before{ border-radius: 8px;}
}
.ldform_divider{ font-size: 27px; font-family: $fstack_helveticalt; color: #e6e7e7; position: relative; margin: 0 0 10px;
    &:before{ content: ""; background: #bbbbba; height: 3px; width: calc(50% - 12.5px); width: -webkit-calc(50% - 25px); position: absolute; left: 0; top: 0; bottom: 0; @include push--tbauto;}
    &:after{ content: ""; background: #bbbbba; height: 3px; width: calc(50% - 12.5px); width: -webkit-calc(50% - 25px); position: absolute; right: 0; top: 0; bottom: 0; @include push--tbauto;}
}
.fb_btn{ background: #3b599c; border-radius: 20px; font-family: $fstack_helveticabd; padding-left: 150px; margin: 0 0 5px; text-align: left;
    i{ font-size: 63px; position: absolute; left: 38px; top: 20px; text-align: left;}
    &:hover{ background: #000; color: #fff;}
    &:before{ border-radius: 18px;}
}
.ldform_terms{ font-size: 18px; color: #fff; font-family: $fstack_helveticaltit; margin: 0 0 55px;
    a{ font-family: $fstack_helveticabdit; }
}
.landing_sublinks{ font-family: $fstack_helveticaltit; font-size: 27px; display: flex; justify-content: space-between;
    a{ color: #e6e7e7; position: relative;
        &:before{ content: ""; background: #bbbbba; height: 2px; position: absolute; bottom: 3px; left: 0; width: 100%;}
        &:after{
            content: attr(data-text); position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0; transform: translate(0, 20%); transition: all 0.3s;}
        &:hover:after{
            opacity: 1;
            transform: translate(0, 0);
        }
        > span{ transition: all 400ms; display: block;}
        &:hover > span{
            opacity: 0;
            transform: translate(0px,-20px);
        }
    }
}
.landing_footer{ border-top: 3px solid #bbbbba; display: flex; justify-content: center; align-items: center; padding: 10px 0 18px;
    span{ font-family: $fstack_dincb; font-size: 24px; padding-right: 22px; color: #fff; letter-spacing: 1px;}
}

/* Signup page */
.signup_content{ padding: 85px 0 12px;
    .lading_logo{ margin: 0 0 35px;
        img{ max-width: 352px;}
    }
    .landing_sublinks{ justify-content: center;}
 }
 .signup_form{ margin: 0 0 55px;}
.choose_file{ position: relative; max-width: 200px; @include push--auto;
    input{ @include overlay(); width: 100%; z-index: 2; opacity: 0; cursor: pointer;}  
    p{ color: #e6e7e8; font-family: $fstack_helveticaltit; display: inline-block; position: relative; margin: 0;
        &:before{ content: ""; background: #bbbbba; height: 2px; position: absolute; bottom: 2px; left: 0; width: 100%;}
        &:after{
            content: attr(data-text); position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0; transform: translate(0, 10%); transition: all 0.3s;}
        > span{ transition: all 400ms; display: block;}        
    }
    &:hover p:after{
        opacity: 1;        
        transform: translate(0, 0);
    }
    &:hover p > span{
        opacity: 0;        
        transform: translate(0px,-10px);
    }  
}
.profile_img{ position: relative; width: 179px; height: 179px; border-radius: 50%; border: 4px solid #fff; @include push--auto; margin-bottom: 6px; pointer-events: none;
    &:before{ content: ""; @include overlay(); background: #fff; border-radius: 50%; z-index: 1;}
    &:after{ content: ""; position: absolute; left: -3px; right: -3px; top: -3px; bottom: -3px; border-radius: 50%; border: 3px solid #d6b860; animation: glow 2s infinite;  animation-timing-function: ease in out; }
    img{ position: relative; z-index: 3; max-width: none; width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 50%;}    
}
.remove_profileimg{ position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0,0,0,0.6) url(../images/close1.png) no-repeat center; background-size: 40px auto; -webkit-background-size: 40px auto; z-index: 3; opacity: 0; transition: all 400ms; border-radius: 50%;}
.remove-image{ position: absolute; left: 0; right: 0; top: 0; bottom: 32px; text-indent: -9999px; font-size: 0; z-index: 3; cursor: pointer;}

.choose_img_available:hover .profile_img .remove_profileimg{ opacity: 1;}

.signup_form{
    .form-group{ margin-bottom: 12px;
        label{ font-size: 31px; font-family: $fstack_helveticalt; color: #fff; font-weight: normal; letter-spacing: 0.5px; margin: 8px 0}
        &.upload_imggroup{ margin: 0 0 40px;}
    }
    .ldform_terms{ margin: 110px 0 5px;}
}
/* Forget password */
.forget_content{
  .landing_sublinks{ justify-content: center;}
}
.forget_form{ margin: 0 0 160px;
  .title1{ letter-spacing: 8px; }
  .btn{ letter-spacing: 2px;}
}
/* Header */
header{ height: 96px;}
.header_in{background: rgb(255,255,255);
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(253,253,253,1) 26%, rgba(239,239,240,1) 49%, rgba(219,219,221,1) 71%, rgba(179,182,183,1) 100%);
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(253,253,253,1) 26%,rgba(239,239,240,1) 49%,rgba(219,219,221,1) 71%,rgba(179,182,183,1) 100%);
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(253,253,253,1) 26%,rgba(239,239,240,1) 49%,rgba(219,219,221,1) 71%,rgba(179,182,183,1) 100%);
padding: 13px 0; position: fixed; left: 0; right: 0; top: 0; max-width: 768px; @include push--auto; z-index: 4;
    .container{ @include spacearround(45px); position: relative;}
}

.mk-css-icon-menu.icon-size-big {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 45px;
    width: 50px;
    height: 32px;
}
.mk-css-icon-menu.icon-size-big div {
    background-color: #2d2d2e   ;
    height: 4px;
    margin-bottom: 10px; transition: all .6s;
    &:last-child{ margin-bottom: 0;}
}
.menu_ico.active .mk-css-icon-menu-line-1 {top: 8px; -webkit-transform: translateY(14px) rotate(45deg) scale(1); transform: translateY(14px) rotate(45deg) scale(1); }
.menu_ico.active .mk-css-icon-menu-line-2 {top: 17px; -webkit-transform: translate(0) rotate(0deg) scale(0); transform: translate(0) rotate(0deg) scale(0); }
.menu_ico.active .mk-css-icon-menu-line-3 {top: 26px; -webkit-transform: translateY(-14px) rotate(-45deg) scale(1); transform: translateY(-14px) rotate(-45deg) scale(1); }

.logo{display: block; @include push--auto; width: 100px;}
.offcanvas_menu{ background:#fff; position: absolute; width: 417px; top: 96px; height: calc(100vh - 96px); opacity: 0; left: 0; visibility: hidden; pointer-events: none; transition: all 600ms;
    &.active{ left: 0; opacity: 1; pointer-events: auto; visibility: visible;}
}
.offcanvas_top{  
   &.smcustom_scroll{ height: 100%; }  
}
.offcanvas_topin{ padding: 50px 24px 30px;}
.canvas_logo{ margin: 0 auto 30px; max-width: 270px;}
.main_menulist{ list-style: none; padding: 0; margin: 0;
    li{ margin: 0; 
        a{padding: 13px 30px 13px 90px; position: relative; display: block; border-radius: 40px;
            i{ position: absolute; left: 25px; top: 0; bottom: 0; @include push--tbauto; width: 44px; height: 34px; line-height: 34px; text-align: center;
                img{ @include overlay(); margin: auto; transition: all 0.3s;}
            } 
            span{ display: inline-block; }
        }
        &.active{
            a{ background: #d6b860; color: #424243;}
            &:hover{
              .mmico{ animation: none;}
              .mmanim_ico{ opacity: 0; }
            }
        }
        &:hover{
            .mmico{ animation: hvr-icon-float-away 0.5s forwards;  animation-timing-function: ease-out;}
            .mmanim_ico{ opacity: 1; }
        }
        &.menulink_account{ margin: 77px 0 0;}
        &.menulink_logout{
            a{ padding-left: 35px; font-family: $fstack_helveticablk; font-size: 22px; color: #231f20;
                &:hover{ color: #d6b860;}
                i{ opacity: 0; left: auto; right: 15px; transition: all 400ms;}

            }
            &:hover{
                a{ 
                    i{ opacity: 1; right: 0px; }
                }
            }
        }
    }
}
.mmanim_ico{ opacity: 0; }
.announce_count{ background: #ed1c24; width: 32px; height: 32px; line-height: 32px; color: #fff; display: inline-block; text-align: center; border-radius: 50%; font-weight: normal; margin-left: 18px;}
.offcanvas_btm{ background: #f1f2f2; /*position: absolute; left: 0; right: 0; bottom: 0; */text-align: center; padding: 40px 60px;
    h5{ text-align: left; font-size: 21px; margin: 0 0 41px;}
}
.scrollToTop{ background: rgba(0, 0, 0, 0.7); width: 40px; height: 40px; line-height: 36px; position: fixed; bottom: 20px; left: 14px; font-size: 24px; border-radius: 2px; text-align: center; color: #fff; z-index: 1; cursor: pointer; opacity: 0; transition: all 400ms; visibility: hidden; pointer-events: none;
  &.active{ opacity: 1; visibility: visible; pointer-events: auto;}
} 
.scrollToTop:hover, .scrollToTop:focus{background: rgba(0, 0, 0, 1); color: #fff;}

/* Dashboard */
.ban_dashboard{ background: url(../images/dashboard_banner.jpg) no-repeat center; background-size: cover; -webkit-background-size: cover; padding: 60px 0 50px;
    .container{ @include spacearround(115px);}
    .lading_logo{ max-width: 306px; @include push--auto; margin-bottom: 26px; }
 }
 .bandash_coins{ margin: 0 0 39px;
    p{color: #d6b861; font-family: $fstack_helveticalt; font-size: 31px; margin: 0 0 6px;}
    span{ background: -webkit-linear-gradient(#fefefe, #a3a5a8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        font-size: 254px; line-height: 0.8; font-family: $fstack_dincb;
    }
}
.dashboard_sec{ padding: 35px 0;}
.dashboard_menu{ list-style: none; display: flex; padding: 0; margin: 0 0 28px;
    li{ width: 25%; padding: 25px 19px 19px; text-align: center; border-right: 1px solid #bcbec0;
        &:last-child{ border: 0;}
        a{ display: block;}
        i{ height: 104px; display: flex; justify-content: center; align-items: center; margin: 0 0 16px; transition: all 300ms; position: relative;
            &:before { content: ''; pointer-events: none; position: absolute; z-index: -1; top: 100%; left: 5%; height: 10px; width: 90%; opacity: 0;
              background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
              background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%); transition-duration: 0.3s; transition-property: transform, opacity;
            }
        }
        &:hover{
            i{transform: translateY(-7px);
                &:before{ opacity: 1; transform: translateY(7px)}
            }
        }
        a:hover{ color: inherit;}
    }    
}
.dashboard_boxsec{ display: flex; justify-content: space-between;}
.dashboard_bx{ width: 311px; height: 311px; border-radius: 12px; text-align: center; overflow: hidden;
    &.dashbx_refer:hover{
        i{ animation-play-state: paused;
            img{ animation-play-state: paused;}
        }
    }
    &.dashbx_best:hover{
         .ico_besthome{ animation-play-state: paused;}
    }
}
.dashbx_refer{ background: rgb(100,101,103); padding: 0 15px 30px;
background: -moz-radial-gradient(center, ellipse cover,  rgba(100,101,103,1) 0%, rgba(65,64,66,1) 100%);
background: -webkit-radial-gradient(center, ellipse cover,  rgba(100,101,103,1) 0%,rgba(65,64,66,1) 100%);
background: radial-gradient(ellipse at center,  rgba(100,101,103,1) 0%,rgba(65,64,66,1) 100%);
    i{ display: block; padding:30px 0 0; margin: 0 auto 13px; background: url(../images/diamon-pattern1.png) repeat 0 0; animation: bg-scrolling-banner 75s infinite;  animation-timing-function: linear; max-width: 245px; 
        img{animation: dpulse 1.5s infinite alternate; animation-timing-function: ease-in-out;}
    }
    p{ color: #fff; font-family: $fstack_helveticabd; text-transform: uppercase; letter-spacing: 0.5px;
        span{ color: #d6b861; font-size: 28px; display: block;}
    }
}
.dashbx_best{ padding: 136px 15px 30px;
    background: url(../images/dashboard_bestbg.jpg) no-repeat; position: relative;
    &:before{ content: ""; background: #d6b860; height: 11px; position: absolute; left: 0; right: 0; top: 0; }
    &:after{ content: ""; background: #d6b860; height: 11px; position: absolute; left: 0; right: 0; bottom: 0; }
    .ico_bestshowroom{ position: absolute; left: 27px; top: 10px; }
    .ico_besthome{ position: absolute; right: 27px; top: 62px; animation: ibesthome_anim 2s infinite alternate; animation-delay: 1s; animation-timing-function: ease-in-out;}
    p{ color: #fff; font-family: $fstack_helveticabd; text-transform: uppercase; letter-spacing: 0.5px; font-size: 23px;
        span{ font-size: 29px; display: block;}
        i{font-style: normal; color: #d6b861; font-size: 27px; }
    }    
}

/* Deals */
.grow_flex{ display: flex; flex-flow: column; justify-content: center;
  .item_list{ flex-grow: 1;}
}
.deals_topbar{ background: url(../images/deals_bg.jpg) no-repeat; background-size: cover; -webkit-background-size: cover;}
.deals_ttrow{ padding: 26px 90px 27px 50px; margin: 0 0 17px; position: relative;
    .title2{ margin: 0;}
    .form-control{ background: rgba(255,255,255,1); border-color: #d6b860; position: absolute; right: 0; top: 0; bottom: 0; border-radius: 0; height: 100%; opacity: 0; pointer-events: none; transition: all 400ms;    
        &.active{ pointer-events: auto; opacity: 1;}
    }
}
.deals_search{ position: absolute; right: 47px; top: 38px; background:  url(../images/search.png) no-repeat center; width: 38px; height: 38px; border: 0; z-index: 1; transition: all 400ms;
    &.active{ background-image:  url(../images/search1.png); right: 20px;}
    &.active + .search_removetext{ opacity: 1; pointer-events: auto;}
    &:focus{ outline: none;}
 }
 .search_removetext{ background:  url(../images/close1.png) no-repeat center; background-size: 18px auto; width: 24px; height: 24px;  position: absolute; right: 67px; top: 42px; border: 0; z-index: 1; opacity: 0; pointer-events: none; transition: all 400ms; 
    &:focus{ outline: none;}
 }
 .tablinks{ display: flex; 
    > a{ font-family: $fstack_helveticalt; color: #fff; font-size: 31px; text-align: center; width: 50%; padding: 28px 10px; position: relative;
        &:after{ content: ""; background: #d6b860; position: absolute; left: 0; width: 0; bottom: 0; height: 8px; opacity: 0; }
        &.active:after{ opacity: 1; width: 100%; transition: all 400ms;}
    }
 }
 .deals_filtersec{ display: flex; background: #e7e7e7;}
 .deals_filtercol{ width: 50%; padding: 13px 23px 25px; border-right: 3px solid #cecfd1;
    &:last-child{ border-right: 0;}
    > label{ margin: 0 0 3px; font-weight: normal; color: #a7a9ac;}    
 }
 .deals_custom_select{
    .css-yk16xz-control, .css-1pahdxg-control{ border-radius: 0; background: none; border: 0; padding-right: 30px;}
    .css-1pahdxg-control { border-radius: 0; box-shadow: none; background: none; border: 0;}
    .css-1pahdxg-control:hover { border-color: #d6b860}
    .css-1hwfws3{ order: 2; padding: 0;}
    .css-1wa3eu0-placeholder{ color: #d6b861; font-size: 28px; text-transform: uppercase;      white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width: 100%;}
    .css-1uccc91-singleValue{ color: #d6b861; font-size: 28px; text-transform: uppercase;}
    .css-1hb7zxy-IndicatorsContainer{ order: 1;}
    .css-1gtu0rj-indicatorContainer,
    .css-tlfecz-indicatorContainer{ width: 30px; height: 30px; padding: 0;      
      &:first-child{ position: absolute; right: 0;}
    }   
     .css-1okebmr-indicatorSeparator{ display: none;}
    .css-6q0nyr-Svg { fill: #d6b860; width: 30px; height: 30px;}
 }
 .search-result-text{ padding: 8px 50px; background: #f5f5f5; display: block; font-size: 16px;}
 .item_row{ padding: 25px 35px 20px 10px; position: relative; border-bottom: 1px solid #e3e4e5; 
    >a{ display: flex;
        &:hover, &:focus{ color: #424243;        
      }
    }
 }
 .item_img{ width: 201px; height: 127px; padding: 10px; border-top: 1px solid #f1f1f1; position: relative; display: flex; justify-content: center; align-items: center;
    img{ width: 100%; height: 100%; object-fit: cover; object-position: center;}
 }
 .offer_label{ position: absolute; left: 0; top: 4px;}
 .deal_status{ padding: 5px 15px; text-align: center; min-width: 138px; font-size: 17px; color: #fff; border-radius: 30px;}
 .deal_status_active{ background: #2bb673;}
 .deal_status_hot{ background: #d6b860;}
 .deal_status_expired{ background: #ed1c24;}
 .item_info{ width: calc(100% - 201px); width: -webkit-calc(100% - 201px); padding: 0 0 50px 25px; position: relative;
    h3{ font-family: $fstack_helveticahv; font-size: 28px; margin: 0 0 14px; transition: all 400ms;
      &:hover{color: #d6b860; }
    }
 }
 .deal_buyoff{ margin: 0;}
 .deal_loc{ color: #a7a9ac; margin: 0; transition: all 400ms; padding-right: 130px; position: absolute; left: 25px; right: 0; bottom: 5px;
    &:hover{ color: #d6b860;}
 }
 .deals_coins{ position: absolute; right: 0; bottom: 0; font-size: 28px; font-family: $fstack_helveticabd;
 }
 .ico_r{ background: url(../images/ico_r.png) no-repeat; background-size: contain; -webkit-background-size: contain; width: 37px; height: 36px; display: inline-block;vertical-align: top; margin-right: 7px; font-size: 0;}
 .ico_rgrey{ background-image: url(../images/ico_r1.png); }
 .btn:hover .ico_rgrey{ background-image: url(../images/ico_r.png); }
 /* Detail */
 .details_topbar{ background: url(../images/deals_bg.jpg) no-repeat; background-size: cover; -webkit-background-size: cover; padding: 30px 40px;
 }
 .back_arrow{ position: relative; display: inline-block; padding-left: 77px; margin: 0;
    &:before{ content: ""; background: url(../images/back_arrow.png) no-repeat; width: 40px; height: 32px; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0;}
 }
 .deals_daysleft{ background: #f6f6f6; padding: 29px 35px 20px;
    p{ color: #ed1c24; margin: 0;
        span{ margin-right: 9px; display: inline-block; vertical-align: middle;
          &.deal_status{ margin-right: 0;}
        }
        a, img{ vertical-align: middle;}
    }
 }
 .detail_img{ 
  img{ width: 100%;}
 }
 .detail_desc{ padding: 25px 33px 60px; border-bottom: 1px solid #e3e4e5;
    font-family: $fstack_helveticahv; font-size: 35px;
 }
 .detail_infolist{ flex-grow: 1;
    &:last-child{ border-bottom: 0;}
 }
 .detail_inforw{ border-bottom: 1px solid #e3e4e5; padding: 30px 50px 39px 104px; font-size: 28px; position: relative;
    i{ position: absolute; left: 50px; top: 28px; text-align: center; width: 39px;}
    h5{ color: #d6b861; font-size: 28px; text-transform: uppercase; margin: 0 0 10px;}
    p{ margin: 0;}
 }
 .redeem_btn{ text-transform: uppercase;
    .ico_rgrey{ margin-top: 2px;}
 }
  

 /* Popup */
 .modal-open{ padding-left: 15px;}
 .modal-dialog{ max-width: 660px;}
 .modal-content{ border: 0; background: none; }
 .popup_sec{ margin-top: 160px; border: 0; background: rgba(255, 255, 255, 0.95); border-radius: 35px;
    .modal-header{ padding: 0; border: 0;
        .close{ position: absolute; left: 0; right: 0; margin: 0 auto; top: 15px; padding: 0; width: 86px; height: 86px; opacity: 1; background: url(../images/close.png) no-repeat;
            &:focus{ outline: none;}
            span{ display: none;}
        }
    }
    .modal-body{ padding: 0;}
 }
 .popup_terms{ padding: 30px 25px 100px 85px; font-size: 22px; font-family: $fstack_helveticalt;
    .title1{ color: #5d5e5e; letter-spacing: 5px; text-transform: uppercase; margin: 0 0 75px;}
    h3{ font-family: $fstack_helveticablk; margin: 0; font-size: 22px; color: #231f20;}
 }
 .terms_content{ padding-right: 60px;}
 .popalert_top{ padding: 90px 50px; text-align: center; min-height: calc(100vh - 290px); min-height: -webkit-calc(100vh - 300px); display: flex; justify-content: center; align-items: center;}
 .popaler_info{
    i{ display: block; margin: 0 0 89px;}
    .title2{ font-family: $fstack_helveticahv; color: #424243; text-transform: uppercase; margin: 0 0 27px;}
    p{ font-size: 31px;}
 }
 .arch_btn{ border-radius: 0 0 35px 35px;
    &:hover, &:focus{ border-color: #fff;}
 }
 .smcustom_scroll{ height: 50vh;
    .smcustom_scroll_in{
        >div:nth-child(2n){ display: none;}
    }
}

 /* Merchants */
 .ban_sec{background: url(../images/dashboard_banner.jpg) no-repeat center; background-size: cover; -webkit-background-size: cover; padding: 85px 0 140px; min-height: 675px; position: relative;
  .container{ @include spacearround(85px);}  
 }
 .ban_info{ color: #fff;
    i{ margin: 0 0 41px; display: block;
      img{animation: dpulse 1.5s infinite alternate; animation-timing-function: ease-in-out;}
    }
    h1{ font-size: 31px; margin: 0 0 26px;}
    p{ margin: 0 0 8px;}
 }
 .scroll_down{ background: url(../images/scroll_down.png) no-repeat; width: 83px; height: 69px; position: absolute; left: 0; right: 0; margin: 0 auto; bottom: 37px; animation: aniPointing ease-in-out 3s infinite;}
 .merchant_sec{ background: #e7e7e7; padding: 45px 0; }
 .merchant_logos{ display: flex; padding: 0; margin: 0; flex-wrap: wrap;
    li{ list-style: none; text-align: center; width: 50%; margin: 0 0 33px;
        p{ margin: 0; font-size: 31px;}
    }
 }
 .mlogo_bx{ background: #fff; border-radius: 10px; height: 310px; width: 310px; margin: 0 auto 10px; display: flex; justify-content: center; align-items: center; padding: 10px;
    img{max-height: 100%;}
 }
    /* Merchant deals */
.merchant_banimg{ background: #fff; height: 390px; display: flex; justify-content: center; align-items: center; padding: 5px;
    img{ max-height: 100%; }
}
 /* Project Gallery */
 .gallery_slider{    
    /*.swiper-slide{ height: 432px;
      .react-transform-component{ height: 100%;}
      .react-transform-element{ height: 100%;
        img{ height: 100%; width: 100%; object-fit: cover; object-position: center;}
      }
    } */   
   .swiper-container-horizontal > .swiper-pagination-bullets{ bottom: 0; box-shadow: 0 0 10px 0 rgba(0,0,0,0.3); height: 95px; padding: 55px 0 0; box-shadow: 0 -80px 35px -18px rgba(0, 0, 0, 0.3) inset}
   .swiper-pagination-bullet{ width: 18px; height: 18px; background: none; border: 2px solid #d3cdcd; opacity: 1; outline: none;
    &.swiper-pagination-bullet-active{ background: #d6b860; border-color: #d6b860;}
   }
   .swiper-button-prev, .swiper-button-next{ background: none; color: #fff; outline: none; transition: all 400ms;
      &:hover{ color: #d6b860;}
      &:after{ transition: all 400ms; }
   }
 }
 .gallery_sliderinfo{ padding: 20px 25px 20px 50px; display: flex; flex-wrap: wrap;
    h3{ font-size: 21px; margin: 0;}
    p{ margin: 0;}
 }
 .galinfo_left{ padding-right: 5px;}
 .galinfo_act{ width: 37%; text-align: right;}
 .pinch_btn{ background: #f1f2f2; border-radius: 2px; padding: 12px 10px 7px 38px; position: relative; color: #666; font-size: 16px; display: inline-block; cursor: default; margin: 9px 0 0;
  &:before{ content: ""; background: url(../images/pinch_hand.png) no-repeat; position: absolute; width: 29px; height: 31px; left: 3px; top: 6px;}
 }
 .pagination_sec{ background: #e7e7e7; padding: 35px 15px 49px;}
 .pagination{ margin: 0; justify-content: center;
  .page-item{ margin: 0;
    &:first-child{
       .page-link{ border-color: transparent; background: url(../images/pleft_arrow.png) no-repeat center left;
        &:hover{ opacity: 0.6;}
        span{ opacity: 0;}
       }
    }
    &:last-child{
       .page-link{ border-color: transparent; background: url(../images/prigh_arrow.png) no-repeat center right;
        &:hover{ opacity: 0.6;}
        span{ opacity: 0;}
       }
    }
    &.active{
      .page-link{ background: #d6b860; border-color: #d6b860; color: #424243; z-index: 1;}
    }
  }
 }
 .page-link{ color: #424243; border-color: #7f7f80; min-width: 44px; min-height: 44px; cursor: pointer; text-align: center; font-family: $fstack_helveticalt; background: none; line-height: normal; padding: 10px 5px;
  &:focus{ box-shadow: none;}
  &:hover{ background: #f7e6b4; border-color: #d6b860; color: #424243;}
 }

/* Refer friend */
.ban_info{
  i.banrefer_ico{ margin: 0 auto 41px; }  
}
.banrefer_ico{ display: block; padding:30px 0 0; background: url(../images/diamon-pattern1.png) repeat 0 0; animation: bg-scrolling-banner 75s infinite;  animation-timing-function: linear; max-width: 245px; 
  img{animation: dpulse 1.5s infinite alternate; animation-timing-function: ease-in-out;}    
}
.refer_earncoins{ color: #d6b861; font-size: 35px; font-family: $fstack_helveticahv; text-transform: uppercase; 
  i{ display: inline-block; margin: 3px 15px 0 0; text-transform: none; color: #1c1910;}
  img{ margin-right: 14px;}
}
.refer_sec{ padding: 17px 0 80px;
  .container{ @include spacearround(100px);}
}
.refer_form{
    .form-group{ margin-bottom: 12px;
        label{ font-size: 31px; font-family: $fstack_helveticalt; font-weight: normal; letter-spacing: 0.5px; margin: 8px 0}        
    }    
    .form-control{ background: #f1f2f2;}    
    .ldform_terms{ margin: 110px 0 5px; color: #424243;
      b{ font-family: $fstack_helveticabdit;}
    }
    .refer_helptext{ margin: 0 0 45px;}
    .contact-field{
        &:after{ background: #f1f2f2;}
    }
}
.contact-field{
    &:after{ content: ""; background: #ffffff; position: absolute; right: 3px; top: 3px; bottom: 3px; width: 50px; border-radius: 0 30px 30px 0;}
}
/* Best showroom */
.ban_info{
  i.banshowroom_ico{ margin: 0 auto 41px; }  
}
.banshowroom_ico{display: block; padding:30px 0 0; background: url(../images/diamon-pattern.png) repeat 0 0; animation: bg-scrolling-banner 75s infinite;  animation-timing-function: linear; max-width: 255px;}
.ban_bestshowroom{
  .ban_info{
    h1{ text-transform: uppercase; font-family: $fstack_helveticabd; line-height: 1; margin: 0;
      span{ font-size: 24px; display: block;}
        i{font-style: normal; color: #d6b861; font-size: 29px; margin: 0;}
    }
  }
}
.bestshow_roomsec{ padding: 50px 0;
  .container{ @include spacearround(100px);}
}
.faqlist{ list-style: none; padding: 0; margin: 0;
  .form-group{ margin: 0 0 36px;
    &.faqbtn_row{ margin-top: 110px;}
  }
  label{ font-family: $fstack_helveticalt; font-size: 31px; letter-spacing: 0.5px; margin: 0 0 15px; 
  font-weight: normal;}
  .custom_select {      
    &.visited_select{ width: 200px;}
    .css-1hwfws3 { padding: 8px 20px 13px; }
    .css-1wa3eu0-placeholder{ color: #0a0d0e; font-family: $fstack_helveticahv; font-size: 28px;}
    .css-1uccc91-singleValue{ color: #0a0d0e; font-family: $fstack_helveticahv; font-size: 28px;}
    .css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer{ padding: 0 10px;}    
    .css-6q0nyr-Svg{ width: 50px; height: 50px;}
  }
  .animated{ animation-fill-mode: none; }
}

/* Announcement */
.announce_sec{ background: #e7e7e7; padding: 45px 0;}
.announce_item{ margin: 0 0 24px; display: block;
  &:last-child{ margin-bottom: 0;}
}
.announce_itemimg{ text-align: center;
    img{ width: 100%;}
}
.announce_iteminfo{ background: #fff; text-align: center; padding: 40px 60px;
 h3{ font-size: 31px; margin: 0 0 15px;} 
}
.popup_announce{
  .popalert_top{ padding: 49px 30px 40px; height: auto; display: block; min-height: initial;}
  .announce_iteminfo{ padding: 40px 0 0; background: none;}
}
.announce_slider{ 
  .swiper-container{ padding-bottom: 40px;}    
   .swiper-container-horizontal > .swiper-pagination-bullets{ bottom: 0; height: 20px; padding: 0;}
   .swiper-pagination-bullet{ width: 18px; height: 18px; background: none; border: 2px solid #d3cdcd; opacity: 1; outline: none;
    &.swiper-pagination-bullet-active{ background: #d6b860; border-color: #d6b860;}
   }
   .swiper-button-prev, .swiper-button-next{ background: none; color: #fff; outline: none; transition: all 400ms; top: 30%; margin-top: 0;
      &:hover{ color: #d6b860;}
      &:after{ transition: all 400ms; }
   }
 }

/* No Record found */
.norecord_sec{ padding: 50px 0;
  h4{ margin: 0; font-size: 21px; font-family: $fstack_helveticabd;}
  p{ font-size: 21px; font-family: $fstack_helveticalt; }
}
.norecord_ico{ background: #F1F0F5 url(../images/norecord.png) no-repeat center; background-size: 50px auto; width: 100px; height: 100px; display: block; margin: 0 auto 9px; border-radius: 50%;}

/*404 page*/
.pgnot_found {background-color: #f5f5f5; height: 100vh; position: relative; display: block; text-align: center; overflow: hidden; color: #fff;
  .pgnot_found_inner{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; width: 240px;
    span{ animation: bounceText 1s ease infinite; position: relative; display: inline-block; padding: 0 10px}   
    .title1{ font-size: 110px; margin: 0 0 10px; position: relative; z-index: 1;  line-height: 1;}
    p{ margin-bottom: 25px; position: relative; z-index: 1}
    .btn{ position: relative; z-index: 1}
    &:after{ content: ''; display: block; background: url(../images/dashboard_banner.jpg) no-repeat center; background-size: cover; -webkit-background-size: cover; width: 500px; height: 500px; border-radius: 50%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4);}
    &:before{ content: ''; display: block; background: url(../images/dashboard_banner.jpg) no-repeat center; background-size: cover; -webkit-background-size: cover; width: 700px; height: 700px; border-radius: 50%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4); }
  }
  &:after{ content: ''; display: block; background: url(../images/dashboard_banner.jpg) no-repeat center; background-size: cover; -webkit-background-size: cover; width: 1000px; height: 1000px; border-radius: 50%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4); }
  &:before{ content: ''; display: block; background: #222; width: 1300px; height: 1300px; border-radius: 50%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.4); }
}

/*sweetalert modal*/
.swal2-modal{
  .swal2-text{ text-align: center;}
  .swal2-actions{ 
    button{ background-color: #d6b860; box-shadow: none; border: 1px solid #d6b860; transition: 0.4s ease all; color: #fff; font-size: 17px; height: 42px; padding: 7px 20px; min-width: 100px; border-radius: 3px; font-weight: normal;
      &:hover{ background-color: #000; border-color: #d6b860; color: #fff;}
    }
  }
  .swal2-footer{ text-align: center;}
}





/* Animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 30%, 0);
        -ms-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -20%, 0);        
        transform: translate3d(0, -20%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}
@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -20%, 0);
        transform: translate3d(0, -20%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes animenuText {
  0%{
      opacity: 0; transform: translateX(60px);
  }
  100%{
      opacity: 1; transform: translateX(0);
  }
}
@-webkit-keyframes animenuText {
  0%{
      opacity: 0; transform: translateX(60px);
  }
  100%{
      opacity: 1; transform: translateX(0);
  }
}

@keyframes animenuIco {
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}
@-webkit-keyframes animenuIco {
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}

@keyframes aniPointing{
  0% {
    transform:  translate(0px,0px)  ;
  }
  15% {
    transform:  translate(0px,-8px)  ;
  }
  30% {
    transform:  translate(0px,0px)  ;
  }
  45% {
    transform:  translate(0px,-8px)  ;
  }
  60% {
    transform:  translate(0px,0px)  ;
  }
  75% {
    transform:  translate(0px,-5px)  ;
  }
  100% {
    transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes aniPointing {
  0% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  15% {
    -webkit-transform:  translate(0px,-8px)  ;
  }
  30% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  45% {
    -webkit-transform:  translate(0px,-8px)  ;
  }
  60% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  75% {
    -webkit-transform:  translate(0px,-5px)  ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  ;
  }
}
@keyframes bounceText{
  0%{ transform: translateY(0px); text-shadow: 0px 10px 5px rgba(0,0,0,0.5)}
  50%{ transform: translateY(-15px); text-shadow: 0px 30px 10px rgba(0,0,0,0.4); }
  100%{ transform: translateY(0px); text-shadow: 0px 10px 5px rgba(0,0,0,0.5)}
}
@-webkit-keyframes bounceText{
  0%{ transform: translateY(0px); text-shadow: 0px 10px 5px rgba(0,0,0,0.5)}
  50%{ transform: translateY(-15px); text-shadow: 0px 30px 10px rgba(0,0,0,0.4); }
  100%{ transform: translateY(0px); text-shadow: 0px 10px 5px rgba(0,0,0,0.5)}
} 
/* Background scroll */
@keyframes bg-scrolling-banner{
   from {background-position: 0 0; }
   to {background-position: 0 -500px; }
}
@-webkit-keyframes bg-scrolling-banner{
   from {background-position: 0 0; }
   to {background-position: 0 -500px; }
}
/* Shadow on focus */
@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 100px 50px rgba(214, 184, 96, 0.6);
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 100px 50px rgba(214, 184, 96, 0.6);
        opacity: 0;
    }
}
/* Glow */
@keyframes glow{
   0% {box-shadow: 0 0 0 0 #fff; transform: scale(1); opacity: 1; pointer-events: none;}
   50% {box-shadow: 0 0 10px 0 #fff; transform: scale(1.1); pointer-events: none;}
   100% {box-shadow: 0 0 15px 0 #fff; transform: scale(1.15); opacity: 0; pointer-events: none;}
}
@-webkit-keyframes glow{
   0% {box-shadow: 0 0 0 0 #fff; transform: scale(1); opacity: 1; pointer-events: none;}
   50% {box-shadow: 0 0 10px 0 #fff; transform: scale(1.1); pointer-events: none;}
   100% {box-shadow: 0 0 15px 0 #fff; transform: scale(1.15); opacity: 0; pointer-events: none;}
}
/* icon fade animation */
@keyframes ibesthome_anim{
   0% { opacity: 0;}
   100% { opacity: 1;} 
}
@-webkit-keyframes ibesthome_anim{
   0% { opacity: 0;}
   100% { opacity: 1;} 
}
/* pluse animation */
@keyframes dpulse{
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(0.97);
  }  
  100% {
      transform: scale(1);
  }
}
@-webkit-keyframes dpulse{
  0% {
      transform: scale(1);
  }
  60% {
      transform: scale(0.97);
  }  
  100% {
      transform: scale(1);
  }
}
/* icon fly animation */
@keyframes hvr-icon-float-away {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;    
    transform: translateX(-100%);
  }
}
@-webkit-keyframes hvr-icon-float-away {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;    
    transform: translateX(-100%);
  }
}


.offcanvas_btm{ opacity: 0; transition: all 600ms; transition-delay: 1.02s; }
.main_menulist li{ 
  a span{ opacity: 0; transform: translateX(60px);} 
  a i{opacity: 0;} 
}
.offcanvas_menu{
  &.active{
    .main_menulist li{ 
      a span{ animation: animenuText 600ms forwards;} 
      a i{ animation: animenuIco 600ms forwards;}       
    }
    .offcanvas_btm{ opacity: 1;}
  }
}
.offcanvas_menu{
  &.active{
    .main_menulist li{ a span, a i{animation-delay: 0.3s; }
      &:nth-child(2) {
        a span, a i{animation-delay: 0.38s;}
      }
      &:nth-child(3) {
        a span, a i{animation-delay: 0.46s;}
      }
      &:nth-child(4) {
        a span, a i{animation-delay: 0.54s;}
      }
      &:nth-child(5) {
        a span, a i{animation-delay: 0.62s;}
      }
      &:nth-child(6) {
        a span, a i{animation-delay: 0.70s;}
      }
      &:nth-child(7) {
        a span, a i{animation-delay: 0.78s;}
      }
      &:nth-child(8) {
        a span, a i{animation-delay: 0.86s;}
      }
      &:nth-child(9) {
        a span{animation-delay: 0.94s;}
      }
    }
  }
}

@import 'responsive';