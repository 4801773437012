
// @font-face
@mixin font-face($style-name, $file, $family, $category:"") {
  $filepath: "../fonts/" + $family + "/" + $file;
  @font-face {
      font-family: "#{$style-name}";
      src: url($filepath + ".eot");
      src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
  }
  %#{$style-name} {
      font: {
          @if $category != "" {
              family: "#{$style-name}", #{$category};
          }
          @else {
              family: "#{$style-name}";
              weight: normal;
          }
      }
  }
}

// @Transform Property
@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
  }

// @Overlay
  @mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
// @Border radius
  @mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
  }
// @margin auto
  @mixin push--auto {
    margin: { 
        left: auto;
        right: auto;
    }
  }
// @margin Topauto
  @mixin push--tbauto {
    margin: { 
        top: auto;
        bottom: auto;
    }
  }
// @left right space
@mixin spacearround($space) {
  padding: { 
        left: $space;
        right: $space;
    }
}

// Variables

