
@media screen and (max-width: 767px){
	.title1{ font-size: 40px; margin: 0 0 15px;}
	.title2{ font-size: 32px;}
	.btn{ height: 55px; font-size: 30px; padding: 10px 40px;}
	.btn-lg{ padding: 19px 30px; height: 76px; font-size: 30px;}
	.btn-sm{ padding: 10px 30px; height: 45px; font-size: 20px;}
	.form-group{ margin: 0 0 12px;}
	.form-control{ height: 45px;}
	label.error{ font-size: 14px !important;}
	/* Landing page */
	.land_content{ padding: 50px 0 12px; }  	
	.lading_logo{ margin: 0 0 30px;
		img{ max-height: 180px; }
	}
	.landing_form{ margin: 0 0 20px;
		.title1{ letter-spacing: 8px;}
		.form-control{ height: 55px; font-size: 23px;}
	}
	.fb_btn{ padding-left: 88px; border-radius: 12px;
		 i{ font-size: 50px; top: 12px; left: 24px;}
	}	
	.ldform_terms{ font-size: 16px; margin: 0 0 35px;}
	.landing_sublinks{ font-size: 24px;}
	/* Forget password */
	.forget_form { margin: 0 0 60px; }
	.landing_footer{
		 span{ font-size: 20px;}
	}
	/* Signup Form */
	.signup_content{
	 	.lading_logo{ margin: 0 0 20px;}
	}
	.signup_form{
	 	.form-group{ 	 		
	 		&.upload_imggroup{ margin: 0 0 20px;}
	 		 label{ font-size: 24px;}
	 	}
	 	.ldform_terms{ margin-top: 50px;}
	 }
	 .profile_img{ width: 149px; height: 149px;}
	 /* Header */
	 header{ height: 76px;}
	 .header_in{ padding: 10px 0;}
	 .logo{ width: 80px;}
	 .mk-css-icon-menu.icon-size-big{ width: 40px; height: 28px; margin-top: -14px;
	 	div{ margin-bottom: 8px;}
	 }	 	 
	.menu_ico.active .mk-css-icon-menu-line-3 {-webkit-transform: translateY(-10px) rotate(-45deg) scale(1); transform: translateY(-10px) rotate(-45deg) scale(1); }
	 .offcanvas_menu{ top: 76px; width: 350px; height: calc(100vh - 76px);}
	 .offcanvas_topin { padding: 35px 15px 20px; }
	 .canvas_logo{ margin: 0 auto 20px;
	 	img{ max-height: 120px;}
	 }
	 .main_menulist{
 	 	li{ margin: 0 0 6px;
 		 	a{ font-size: 18px; padding: 11px 15px 11px 70px;
 		 		i{ left: 15px;}
 		 	}
 		 	&.menulink_account{ margin-top: 25px;}
 		 	&.menulink_logout{ 
 		 		a{ padding-left: 22px; font-size: 19px;}
 		 	}
 	 	} 	 	
	 }
	 .offcanvas_btm{ padding: 20px 35px;
	 	h5{ font-size: 18px; margin: 0 0 20px;}
	 }
	 .canvas_flogo{
	 	img{ max-width: 150px;}
	 }
	 /* Dashboard */
	 .ban_dashboard{ padding: 40px 0;
	 	.container{ @include spacearround(75px);}
	 	.lading_logo{ max-width: 226px; margin-bottom: 18px;}
	 }
	 .bandash_coins{ margin: 0 0 28px;
	 	span{ font-size: 204px;}
 	 	p{ font-size: 26px;}
	 }
	 .dashboard_menu{
	 	 li{ font-size: 18px; padding: 15px 10px 10px;
 	 	 	i{ height: 70px;
 	 	 		img{ max-height: 100%;}
 	 	 	}
	 	 }
	 }
	 .dashboard_boxsec{ justify-content: space-around;}
	 .dashboard_bx{
	 	width: 250px; height: 250px;
	 }
	 .dashbx_refer{
 	 	i{ max-width: 180px; background-size: cover;
 		 	img{ max-width: 120px;}
 	 	}
 	 	p{ font-size: 18px;
 	 		span{ font-size: 23px;}
 	 	}
	 }
	 .dashbx_best{
 	 	p{ font-size: 18px;
 	 		span{ font-size: 23px;}
 	 		i{ font-size: 23px;}
 	 	}
	 }
	 /* Deals */
	 .deals_ttrow{padding: 12px 80px 12px 30px; margin: 0 0 12px;}
	 .deals_search{ top: 14px; right: 20px; background-size: 30px auto; -webkit-background-size: 30px auto;}
	 .search_removetext{ top: 14px;}
	 .tablinks{
 	 	> a{ padding: 15px 10px; font-size: 24px;
 	 		&:after{ height: 5px;}
 	 	}
	 }
	 .deals_filtercol{ padding: 8px 15px 10px;
 	 	> label{ font-size: 18px;}
	 }
	 .deals_custom_select{
 	 	.css-1wa3eu0-placeholder{ font-size: 21px;}
 	 	.css-1uccc91-singleValue{ font-size: 21px;}
	 }
	 .item_row{ padding: 12px 15px 12px 10px;}
	 .item_img{ padding: 5px; width: 180px; height: 117px;}
	 .item_info{ padding: 0 0 50px 10px; width: calc(100% - 180px); width: -webkit-calc(100% - 180px);
	 	h3{ font-size: 25px; margin: 0 0 10px;}
	 }
	 .deals_coins{ font-size: 24px; }
	 .ico_r{ width: 30px; height: 30px;}
	 .deal_loc{ left: 10px; bottom: 3px; padding-right: 110px;}
	 /* Detail */
	 .details_topbar{ padding: 20px 30px;}
	 .back_arrow{ padding-left: 50px;
	 	&:before{ width: 30px; height: 22px; background-size: 30px auto; -webkit-background-size: 30px auto;}
	 }
	 .deals_daysleft{ padding: 18px 25px 15px;}
	 .detail_desc{ padding: 15px 25px 45px; font-size: 30px;}
	 .detail_inforw{ padding: 20px 30px 29px 64px; font-size: 24px;
	 	i{ width: 30px; left: 25px; top: 18px;
	 		img{ max-height: 30px;}
	 	}
	 	h5{ font-size: 24px; margin: 0 0 6px;}
	 }
	 .ico_r{ margin-right: 5px;}
	 /* Merchants */
	 .merchant_sec{ padding: 30px 0;}
	 .ban_sec{ padding: 50px 0 100px; min-height: 450px;
	 	.container{ @include spacearround(50px);}
	 }
	 .ban_info{ font-size: 18px;
	 	i{ margin: 0 0 20px;}
 	 	h1{ font-size: 28px; margin: 0 0 14px;}
	 }
	 .scroll_down{ width: 63px; height: 52px; background-size: cover; bottom: 17px;}
	 .mlogo_bx{ width: 250px; height: 250px;}
	 .merchant_logos{
 	 	li{ margin: 0 0 20px;
 		 	p{ font-size: 24px;}
 	 	}
	 }
	 .merchant_banimg{ height: 350px;}
	 /* Gallery */
	 .gallery_slider{
	 	.swiper-button-prev, .swiper-button-next{
	 		&:after{ font-size: 35px;}
	 	}
	 }
	 .gallery_sliderinfo{ padding: 15px 25px 15px 45px;}	 
	 .pagination_sec{ padding: 20px 15px 30px;}
	 /* Refer friend */
	 .ban_info{
	 	i.banrefer_ico{ margin-bottom: 20px;}
	 } 
	 .refer_earncoins{ font-size: 30px;
 	 	i{ margin: 3px 15px 0 0;}
	 }
	 .refer_sec{ padding: 15px 0 60px;
 	 	.container{ @include spacearround(80px); }
	 }
	 .refer_form{
	 	.form-group{
	 		label{ font-size: 26px;}
	 	} 
	 	.ldform_terms{ margin-top: 70px;}
	 	.refer_helptext{ margin: 0 0 25px;}
	 } 
	 /* Showroom */
	 .bestshow_roomsec{ padding: 30px 0;
	 	.container{ @include spacearround(80px); }
	 }
	 .faqlist{
 	 	label{ font-size: 26px;}
 	 	.form-group{ margin: 0 0 28px;
 	 		&.faqbtn_row{ margin-top: 70px;}
 	 	}
 	 	.custom_select {      
		    .css-1hwfws3 { padding: 5px 15px; }
		    .css-1wa3eu0-placeholder{ font-size: 21px;}
		    .css-1uccc91-singleValue{ font-size: 21px;}
		    .css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer{ padding: 7px 15px;}    
		    .css-6q0nyr-Svg{ width: 30px; height: 30px;}
		  }
	 }	 
	 /* Announcement */
	 .announce_item{ margin: 0 0 15px;}
	 .announce_iteminfo{ padding: 20px 30px;
	 	h3{ font-size: 28px; margin: 0 0 6px;}
	 	p{ margin: 0;}
	 }


	 /* Popup */
	 .popup_sec{ margin-top: 80px;
 	 	.modal-header{
 		 	.close{ width: 66px; height: 66px; background-size: cover; -webkit-background-size: cover; top: 0px;}
 	 	}
	 }
	 .popup_terms{ padding: 30px 25px 60px 45px; font-size: 20px;
	 	.title1{ margin: 0 0 40px; letter-spacing: 2px;}
	 }
	 .terms_content{ padding-right: 30px;}	
	 .popalert_top{ padding: 90px 30px; } 
	 .popaler_info{
 	 	i{ margin: 0 0 40px;
 	 		img{ max-height: 150px;} 
 	 	}
 	 	.title2{ margin: 0 0 15px;}
 	 	p{ font-size: 26px;
	 	 	br{ display: none;}
	 	 }
	 }
	 .popup_announce{
 	 	.popalert_top{ padding: 30px 20px 30px;}
 	 	.announce_iteminfo{ padding: 20px 0 0;}
 	 	h3{ font-size: 28px;}
	 }	 
	 .announce_slider{
	 	.swiper-button-prev, .swiper-button-next{
	 		&:after{ font-size: 35px;}
	 	}
	 }
}
@media screen and (max-width: 650px){
	.container{ @include spacearround(30px);}
	.scrollToTop{ bottom: 10px; left: 8px !important; width: 30px; height: 30px; line-height: 27px; font-size: 20px;}
	/* Landing page */
	.land_content{
		.container{ @include spacearround(75px);}	
	} 	
	/* Header */
	 .header_in{
 	 	.container{ @include spacearround(30px);} 	 	
	 }
	 .mk-css-icon-menu.icon-size-big{ left: 30px;}
	 /* Merchants */
	 .mlogo_bx{ width: 230px; height: 230px;}
	 /* Gallery */
	 .gallery_sliderinfo{ padding: 15px 15px 15px 30px;}
	 .pinch_btn{ margin: 5px 0 0; font-size: 14px; }

}
@media screen and (max-width: 580px){
	body{ font-size: 18px;}
	p{ margin: 0 0 15px;}
	.title1{ font-size: 36px;}
	.title2{ font-size: 28px;}
	.btn{ height: 45px; font-size: 24px; padding: 7px 24px; letter-spacing: 2px;}
	.btn-sm{ padding: 8.5px 20px; height: 40px; font-size: 18px; letter-spacing: 0;}
	.btn-lg{ padding: 15px 20px; height: 60px; font-size: 24px; letter-spacing: 0;}
	.btn_minwid{ min-width: 170px;}
	.form-control{ height: 40px;}
	.custom_checkbox{
 		span{ padding-top: 7px; padding-bottom: 7px;}
	}	
	.container{ @include spacearround(15px);}
	/* Landing page */
	.land_content{ padding: 30px 0 12px;
		.container{ @include spacearround(50px);}	
	} 
	.lading_logo{ margin: 0 0 20px;
		img{ max-height: 130px; }
	}
	.landing_form{ margin: 0 0 10px;
		.title1{ letter-spacing: 5px; text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.9); margin: 0 0 10px;}
		.form-control{ height: 45px; font-size: 21px;}
	}
	.ldform_divider{ font-size: 24px;
		&:before,&:after{ height: 2px;}
	}
	.fb_btn{ padding-left: 65px; border-radius: 8px;
		 i{ font-size: 40px; top: 10px; left: 15px; }
	}
	.landing_sublinks{ font-size: 20px;}
	/* Forget password */
	.forget_form { margin: 40px 0 60px; }
	/* Signup Form */
	.signup_form{ margin: 0 0 40px;	
	 	.form-group{
	 		 label{ font-size: 20px; margin: 5px 0;}
	 	}	 	
	 }	 
	 /* Header */
	 .canvas_logo{ margin: 0 auto 10px;
	 	img{ max-height: 90px;}
	 }
	 .offcanvas_menu{ width: 300px;}
	 .offcanvas_topin{ padding: 20px 10px 10px;}
	 .main_menulist{
 	 	li{
 		 	a{ font-size: 16px; padding: 11px 15px 11px 60px;
 		 		i{ left: 10px;}
 		 	}
 	 	}
	 }
	 .offcanvas_btm{ padding: 15px 35px;
	 	h5{ font-size: 16px; margin: 0 0 15px;}
	 }
	 .canvas_flogo{
 	 	img{ max-width: 140px;}
	 }
	 /* Dashboard */
	 .ban_dashboard{ 
	 	.container{ @include spacearround(50px);}	 	
	 }
	 .bandash_coins{
	 	span{ font-size: 174px;}
	 } 
	 .dashboard_sec{ padding: 20px 0 30px;}
	 .dashboard_menu{ margin: 0 0 18px;
	 	 li{ font-size: 15px; padding: 10px 5px 5px;
 	 	 	i{ height: 50px; margin: 0 0 10px;}
	 	 }
	 }
	 .dashboard_bx{ width: 200px; height: 200px;}
	 .dashbx_refer{
 	 	i{ padding: 10px 0 0; margin-bottom: 8px;
 	 		img{ max-width: 90px;}
 	 	}
 	 	p{ font-size: 16px;
 	 		span{ font-size: 20px;}
 	 	}
	 }
	 .dashbx_best{ padding: 80px 15px 20px;
	 	.ico_bestshowroom{ max-width: 60px; left: 10px;}
	 	.ico_besthome{ max-width: 80px; top: 27px; right: 10px;}
	 	p{ font-size: 16px;
	 		span{ font-size: 20px;}
	 		i{ font-size: 20px;}
	 	}
	 }
	 /* Detail */
	 .deals_search{ background-size: 24px auto; -webkit-background-size: 24px auto; top: 10px; right: 10px;
	 	&.active{ right: 10px;}
	 } 
	 .tablinks{
 	 	> a{ font-size: 20px}
	 }
	 .deals_filtercol{ padding: 8px 10px 10px;
 	 	> label{ font-size: 16px;}
	 }
	 .deals_custom_select{
	 	.css-yk16xz-control,.css-1pahdxg-control{ min-height: 30px; padding-right: 24px}
 	 	.css-1wa3eu0-placeholder{ font-size: 18px;}
 	 	.css-1uccc91-singleValue{ font-size: 18px;}
 	 	.css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer{ width: 24px; height: 24px;}
 	 	.css-6q0nyr-Svg{ width: 24px; height: 24px;}
	 }
	 .deal_status{ min-width: 118px; font-size: 15px;}
	 .item_info h3{ font-size: 23px; margin: 0 0 14px;}
	 .deal_loc{ padding-right: 90px;}	 
	 .deals_coins{ font-size: 20px;}
	 .ico_r{ width: 24px; height: 24px; }
	 /* Detail */	 	 
	 .ico_r{ margin-right: 4px;}
	 /* Merchants */
	 .ban_sec{ padding: 35px 0 100px; min-height: 400px;
	 	.container{ @include spacearround(30px);}
	 }
	 .scroll_down{ width: 48px; height: 39px;}
	 /* Merchant */
	 .mlogo_bx{ width: 200px; height: 200px; margin-bottom: 7px;}
	 .merchant_logos li p{ font-size: 21px;}
	 .merchant_banimg{ height: 300px;}
	 /* Gallery */
	 .gallery_slider{
	 	.swiper-container-horizontal > .swiper-pagination-bullets{ padding: 65px 0 0;}
	 }
	 .gallery_sliderinfo h3{ font-size: 18px;}
	 .page-link{ min-width: 34px; min-height: 34px; padding: 7px 5px;}
	 .pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link{ background-size: 13px auto; -webkit-background-size: 13px auto;}
	 /* Refer friend */
	 .banrefer_ico{ padding: 0;}
	 .refer_earncoins{ font-size: 26px;}
	 .refer_sec{ padding: 10px 0 40px;
 	 	.container{ @include spacearround(50px); }
	 }
	 .refer_form{
	 	.form-group{ margin-bottom: 8px;
	 		label{ font-size: 21px;}
	 	} 
	 	.ldform_terms{ margin-top: 40px;}
	 	.refer_helptext{ margin: 0 0 15px;}
	 } 
	 /* Showroom */
	 .bestshow_roomsec{ 
	 	.container{ @include spacearround(50px); }
	 }
	 .faqlist{
 	 	label{ font-size: 21px;}
 	 	.form-group{ margin: 0 0 24px;}
 	 	.custom_select {      
		    .css-1hwfws3 { padding: 4px 15px 8px; }
		    .css-1wa3eu0-placeholder{ font-size: 18px;}
		    .css-1uccc91-singleValue{ font-size: 18px;}
		    .css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer{ padding: 5px 10px;}    
		    .css-6q0nyr-Svg{ width: 24px; height: 24px;}
		  }
	 }
	 /* Announcement */
	 .announce_iteminfo{ padding: 16px 15px 20px;
	 	h3{ font-size: 24px; margin: 0 0 6px;}
	 }

	 /* Popup */
	 .popup_sec{ margin-top: 65px; border-radius: 20px;
 	 	.modal-header{
 		 	.close{ width: 40px; height: 40px; top: 8px;}
 	 	}
	 }
	 .popup_terms{ padding: 30px 15px 30px 25px; 
	 	.title1{ margin: 0 0 20px;}
	 	h3{ font-size: 20px;}
	 }
	 .terms_content{ padding-right: 30px;}
	 .popalert_top{ padding: 16px 15px; min-height: 400px;}
	 .popaler_info{
	 	 i{ margin: 0 0 24px;
 	 		img{ max-height: 100px;}
	 	 } 
	 	 p{ font-size: 21px;}
	 }
	 .arch_btn{ border-radius: 0 0 20px 20px;}
	 .arch_btn .ico_rgrey{ margin-top: 2px;}
	 .popup_announce{
 	 	.popalert_top{ padding: 20px 15px 20px;}
 	 	.announce_iteminfo{ padding: 15px 0 0;}
 	 	h3{ font-size: 24px; margin: 0 0 10px;}
	 }	 
	 /* 404 page */
	 .pgnot_found .pgnot_found_inner .title1{ font-size: 90px;}

}
@media screen and (max-width: 480px){	
	body{ font-size: 16px;}
	.title1{ font-size: 32px;}
	.title2{ font-size: 24px;}
	.btn{ letter-spacing: 1px;}
	.btn { padding: 6.5px 20px; height: 40px; font-size: 21px; }
	.btn-sm{ padding: 6px 20px; height: 35px; font-size: 18px; letter-spacing: 0;}
	.btn-lg { padding: 11.5px 20px; height: 50px; font-size: 21px; }
	.form-group{ margin: 0 0 9px;}
	.form-control{ height: 35px;}
	label.error{ font-size: 13px !important;}
	/* Landing page */
	.land_content{
		.container{ @include spacearround(30px);}	
	} 
	.lading_logo{ margin: 0 0 10px;}
	.landing_form{ margin: 0 0 10px;
		.title1{ letter-spacing: 4px; margin: 0 0 5px;}
		.form-control{ height: 40px; font-size: 18px;}
	}
	.fb_btn{ padding-left: 60px; letter-spacing: 0.5px;
		 i{ font-size: 34px; top: 7px;  }
	}	
	.landing_sublinks{ font-size: 18px;}
	/* Forget password */
	.forget_form{ margin: 40px 0 60px;}
	/* Signup Form */
	.signup_form{  margin: 0 0 30px;		
	 	.form-group{ margin: 0 0 8px;
	 		 label{ font-size: 18px;}
	 	}
	 	.ldform_terms{ margin-top: 30px;}
	 }
	 /* Header */
	 header{ height: 62px;}	 
	 .header_in{
 	 	.container{ @include spacearround(15px);} 	 	
	 }
	 .logo{ width: 60px;}
	 .mk-css-icon-menu.icon-size-big{ height: 23px; margin-top: -11px; left: 15px;
	 	div{ height: 3px; margin-bottom: 7px; }
	 }
	 .menu_ico.active .mk-css-icon-menu-line-3 {-webkit-transform: translateY(-6px) rotate(-45deg) scale(1); transform: translateY(-6px) rotate(-45deg) scale(1); }
	 .offcanvas_menu{ top: 62px; height: calc(100vh - 62px); width: 275px;}
	 .offcanvas_btm{ padding: 10px 15px; text-align: left;
 	 	h5{ display: inline-block; margin: 0 30px 0 0; vertical-align: middle;}
	 }
	 .canvas_flogo{ display: inline-block; vertical-align: middle;
 	 	img{ max-width: 100px;}
	 }
	 /* Dashboard */
	 .ban_dashboard{ padding: 30px 0;
	 	.container{ @include spacearround(30px);}
	 	.lading_logo{ max-width: 176px; margin-bottom: 12px;}
	 }
	 .bandash_coins{ margin: 0 0 15px;
	 	p{ font-size: 21px;}
	 	span{ font-size: 134px;}
	 } 
	 .dashboard_sec{ padding: 10px 0 20px;}
	 .dashboard_menu{ flex-flow: column; margin: 0 0 10px;
	 	 li{ width: auto; border-right: 0; border-bottom: 1px solid #bcbec0; padding: 11px 0;
	 	 	i{ width: 40px; height: 40px; margin: 0 15px 0 0;}
	 	 	a{ display: flex; align-items: center;
	 	 		span{
	 	 			br{ display: none;}
	 	 		}
	 	 	}
	 	 }
	 }
	 .dashboard_boxsec{ justify-content: space-between;}

	 /* Deals */
	 .deals_ttrow{ padding: 12px 80px 12px 15px;}
	 .deals_filtercol { padding: 6px 10px 5px; }
	 .deals_custom_select{
 	 	.css-1wa3eu0-placeholder{ font-size: 16px;}
 	 	.css-1uccc91-singleValue{ font-size: 16px;}
 	 	.css-1gtu0rj-indicatorContainer, .css-tlfecz-indicatorContainer{ width: 20px; height: 20px;}
 	 	.css-6q0nyr-Svg{ width: 20px; height: 20px;}
	 }
	 .item_img{ width: 150px; height: 97px;}
	 .item_info{ padding: 0 0 45px 10px; width: calc(100% - 150px); width: -webkit-calc(100% - 150px);
	 	h3{ font-size: 21px; margin: 0 0 6px;}
	 }
	 .ico_r{ margin-right: 3px;}
	 /* Detail */
	 .details_topbar{ padding: 13px 15px;}
	 .back_arrow{ padding-left: 40px; 
	 	&:before{ width: 24px; height: 19px; background-size: 24px auto; -webkit-background-size: 24px auto;}
	 }
	 .deals_daysleft{ padding: 13px 15px 10px; }
	 .detail_desc{ padding: 10px 15px 35px; font-size: 26px;}
	 .detail_inforw{ padding: 15px 15px 15px 40px; font-size: 18px;
	 	i{ width: 22px; left: 12px; top: 14px;
	 		img{ max-height: 22px;}
	 	}
	 	h5{ font-size: 18px; margin: 0 0 4px;}
	 }
	 .redeem_btn .ico_rgrey{ margin-top: 0;}
	 /* Merchants */
	 .ban_sec{ padding: 35px 0 100px; min-height: 400px;
	 	.container{ @include spacearround(15px);}
	 }
	 .ban_info{ font-size: 16px;
	 	h1{ font-size: 24px; margin: 0 0 10px;}
	 }
	 .swiper-pagination-bullet{ width: 14px; height: 14px;}
	 .swiper-container-horizontal{
 	 	> .swiper-pagination-bullets{ padding: 70px 0 0;}
	 }
	 /* Merchant */
	 .mlogo_bx{ width: 190px; height: 190px;}
	 .merchant_logos{
 	 	li{ margin: 0 0 15px;
 		 	p{ font-size: 18px;}
 	 	}
	 }
	 .merchant_banimg{ height: 250px;}
	 /* Gallery */
	 .gallery_slider{
	 	.swiper-button-prev, .swiper-button-next{
	 		&:after{ font-size: 28px;}
	 	}
	 	.swiper-container-horizontal > .swiper-pagination-bullets{ padding: 65px 0 0;}
	 	.swiper-pagination-bullet{ width: 14px; height: 14px;}
	 }
	 .gallery_sliderinfo{ padding: 12px 15px;
	 	h3{ font-size: 16px;}
	 }	 
	 /* Refer friend */
	 .refer_sec{
 	 	.container{ @include spacearround(30px);}
	 }
	 .refer_form{
	 	.form-group{
	 		label{ font-size: 18px;}
	 	} 
	 }
	 /* Showroom */
	 .bestshow_roomsec{ 
	 	.container{ @include spacearround(30px); }
	 }
	 .banshowroom_ico{ padding: 0;}
	 .faqlist{
	 	label{ font-size: 19px; margin: 0 0 8px;}
	 	.form-group{ margin: 0 0 18px;
	 		&.faqbtn_row{ margin-top: 40px;}
	 	}
	 } 
	 /* Announcement */
	 .announce_item{ margin: 0 0 15px;}
	 .announce_iteminfo{ padding: 16px 10px 20px; 
	 	h3{ font-size: 21px;}
	 }
	 /* Popup */
	 .popup_terms{ padding: 20px 15px 20px 18px; }
	 .terms_content { padding-right: 20px; }
	 .popalert_top{ min-height: 350px;}
	 .popaler_info{
	 	 i{ margin: 0 0 15px;
 	 		img{ max-height: 80px;}
	 	 } 
	 	 .title2{ margin: 0 0 10px; font-size: 21px;}
	 	 p{ font-size: 18px; }
	 }
	 .popup_announce h3{ font-size: 22px; margin: 0 0 7px;}
	 .announce_slider{
	 	.swiper-container{ padding-bottom: 35px;}
	 	.swiper-button-prev, .swiper-button-next{
	 		&:after{ font-size: 28px;}
	 	}
	 	.swiper-container-horizontal > .swiper-pagination-bullets{ height: 16px;}
	 	.swiper-pagination-bullet{ width: 14px; height: 14px;}
	 }

}
@media screen and (max-width: 450px){
	/* Dashboard */
	.dashboard_boxsec{ flex-flow: column; align-items: center;}
	.dashboard_bx{ margin-bottom: 15px; width: 250px; height: 250px;}
	.dashbx_refer{
	 	i{ padding: 20px 0 0;
		 	img{ max-width: 120px;}
	 	}
	}
	.dashbx_best{ padding: 136px 15px 30px;
	 	.ico_bestshowroom{ max-width: none;}
	 	.ico_besthome{ max-width: none; right: 27px; top: 62px;}
	 	p{ font-size: 18px;
	 		span{ font-size: 23px;}
	 		i{ font-size: 23px;}
	 	}
	}
	/* Deals */
	.tablinks{
 	 	> a{ font-size: 18px}
	 }
	 .item_row > a{ flex-flow: column; align-items: center;}
	 .item_img{ width: 201px; height: 127px;}
	 .item_info{ padding: 10px 0 35px 0; width: 100%;
	 	h3{ margin: 0 0 8px;}
	 }	 
	 .deal_loc{ left: 0;}
	 /* Detail */	 
	 .redeem_btn{ letter-spacing: 0; font-size: 18px;  padding: 11.5px 10px;
	 	.ico_rgrey{ margin-top: 0;}
	 }	 
	 /* Merchant */
	 .merchant_logos{ flex-flow: column; align-items: center;
	 	li{ width: 100%;}
	 }
	 /* Gallery */
	 .galinfo_left{ width: 100%; padding-right: 0;}
	 .galinfo_act{ width: 100%; text-align: left;}
	 .pinch_btn{
	 	&:before{ background-size: 24px auto; padding: 12px 10px 7px 38px;}
	 }
	 /* Popup */
	 .arch_btn .ico_rgrey{ margin-top: 0;}
}
@media screen and (max-width: 380px){	
	/* Landing page */
	.land_content{
		.container{ @include spacearround(15px);}	
	} 
	.fb_btn{ padding-left: 45px; font-size: 18px;
		i{ font-size: 30px; top: 10px; left: 10px; }
	}
	/* Dashboard */
	 .ban_dashboard{ 
	 	.container{ @include spacearround(15px);}
	 }	 
	 /* Deals */
	.tablinks{
 	 	> a{ font-size: 16px}
	 }
	 /* Detail */
	 .redeem_btn{ letter-spacing: 0; font-size: 18px; 
	 	.ico_rgrey{ margin-top: -2px;}
	 }	 
	 /* Popup */
	 .arch_btn{ letter-spacing: 0; font-size: 18px; padding: 13.5px 15px;}
	 .arch_btn .ico_rgrey{ margin-top: -2px;}
	 /* Refer friend */
	 .refer_sec{
 	 	.container{ @include spacearround(15px);}
	 }
	 /* Showroom */
	 .bestshow_roomsec{ 
	 	.container{ @include spacearround(15px); }
	 }

}
